import {
  scaffolderPlugin,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder';
import BaseCustomInput from './BaseCustomInput';

export const BaseCustomInputExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'BaseCustomInputExtension',
    component: BaseCustomInput,
  }),
);

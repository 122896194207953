import React, { useCallback, useEffect, useState } from 'react';

import { FormControl, TextField } from '@material-ui/core';

const BaseCustomInput = (props) => {
  const {
    onChange,
    rawErrors,
    required,
    formData: inputValue,
    formContext,
    schema,
    name,
  } = props;

  const { formData } = formContext;
  const formFieldsQuantity = Object.keys(formData).length;

  const [defaultValue, setDefaultValue] = useState();

  const getFieldToExtract = (value) => {
    const startPosition = value.indexOf('{') + 2;
    const endPosition = value.indexOf('}');

    const fieldToExtract = value.substring(startPosition, endPosition).trim();

    const firstPartToConcat = value.substring(0, startPosition - 2);
    const secondPartToConcat = value.substring(endPosition + 2);

    return { fieldToExtract, firstPartToConcat, secondPartToConcat };
  };

  const getDefaultValue = useCallback(() => {
    if (!formData[name] || !schema.default) return;

    if (!formData[name].includes('{')) {
      setDefaultValue(formData[name]);
      return;
    }

    let value = schema.default.toString();

    while (value.includes('{')) {
      const { fieldToExtract, firstPartToConcat, secondPartToConcat } =
        getFieldToExtract(value);

      value = firstPartToConcat.concat(
        formData[fieldToExtract],
        secondPartToConcat,
      );
    }

    onChange(value);

    setDefaultValue(value);
  }, [formData, name, schema.default, onChange]);

  useEffect(() => {
    getDefaultValue();
  }, [formFieldsQuantity, getDefaultValue]);

  if (
    schema.default &&
    schema.default.toString().includes('{') &&
    !defaultValue
  )
    return null;

  return (
    React.createElement(FormControl, {
      margin: "normal",
      required: required,
      error: rawErrors?.length > 0 && !inputValue,
      onChange: onChange,}

      , React.createElement(TextField, {
        id: "standard-basic",
        label: "Standard",
        variant: "standard",
        onChange: e => onChange(e.currentTarget.value),
        defaultValue: defaultValue,}
      )
    )
  );
};

export default BaseCustomInput;

import React from 'react';

export const readymindgocloudLogoFull = () => {
  return (
    React.createElement('svg', {
      width: "140",
      height: "34",
      viewBox: "0 0 140 34"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      'aria-labelledby': "catTitle",
      role: "img",}

      , React.createElement('title', { id: "catTitle",}, "RM Logo" )
      , React.createElement('g', { clipPath: "url(#clip0_2_14)",}
        , React.createElement('path', {
          d: "M51.5219 9.38047H33.138V14.4242H39.6431V33.3266H45.064V14.4242H51.5219V9.38047Z" ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M53.5488 22.2963C53.7374 21.5421 53.8788 20.7407 54.5859 19.9394C55.1044 19.4209 55.9529 18.8081 57.2256 18.8081C58.1212 18.8081 59.1111 19.138 59.771 19.798C60.5253 20.5993 60.6667 21.5421 60.7609 22.2963H53.5488ZM62.0337 26.8215C61.3737 28.0943 60.431 29.9327 57.5556 29.9327C54.7273 29.9327 53.5017 27.7172 53.5488 25.5488H66.1818C66.1347 23.569 65.9933 20.0808 63.6835 17.6296C61.4209 15.1785 58.3569 15.0842 57.3199 15.0842C50.3434 15.0842 48.2694 20.5522 48.2694 24.8418C48.2694 30.6397 51.9461 33.9865 57.1785 33.9865C60.0067 33.9865 62.3165 32.9024 63.8249 31.4882C64.7205 30.6397 65.3333 29.7441 65.9461 28.8485L62.0337 26.8215Z"                                                               ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M73.5825 17.2525C74.101 16.734 74.431 16.3098 75.3266 15.8855C76.1279 15.5556 77.3064 15.2256 78.532 15.2256C79.569 15.2256 80.7475 15.4141 81.6902 15.9327C83.5286 16.9226 84.0471 18.5253 84.0471 21.2593V33.3266H78.862V23.3805C78.862 21.7778 78.8148 21.2121 78.6263 20.7407C78.202 19.798 77.3064 19.3737 76.3165 19.3737C73.5825 19.3737 73.5825 21.5892 73.5825 23.7576V33.2795H68.3502V15.697H73.5825V17.2525Z"                                    ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M96.5859 24.8418C96.5387 26.6801 96.4916 28.2828 94.7475 29.367C94.1818 29.697 93.6162 29.9327 92.9562 29.9327C91.7306 29.9327 90.9764 28.9899 90.9764 28.1414C90.9764 26.7744 92.3434 26.303 93.1919 25.9259L96.5859 24.8418ZM91.8721 20.6465C92.0135 19.9865 92.2963 18.5724 94.6061 18.5724C96.4445 18.5724 96.5387 19.9394 96.5859 20.7407V21.6835C94.3704 22.1077 91.0236 22.7205 89.6094 23.2862C88.1482 23.899 85.8855 25.5017 85.8855 28.3771C85.8855 30.8283 87.6296 33.7508 91.6835 33.7508C94.2761 33.7508 95.7845 32.6195 97.1044 31.6768V33.2795H102.195C101.771 32.6667 101.677 32.3838 101.677 30.4983V20.2694C101.677 15.697 96.4445 15.1785 94.2761 15.1785C92.202 15.1785 90.128 15.4613 88.4781 16.734C86.8754 18.0067 86.734 19.4209 86.6397 20.6465H91.8721Z"                                                                         ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M109.69 17.4882C110.822 16.4983 112.094 15.7441 114.781 15.4613V20.0808L113.037 20.3636C110.539 20.7879 109.784 21.165 109.784 23.4276V33.3737H104.599V15.6027H109.69V17.4882Z"            ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M116.808 9.38047H121.946V13.3872H116.808V9.38047ZM116.808 15.6027H121.946V33.2795H116.808V15.6027Z"  ,
          fill: "white",}
        )
        , React.createElement('path', {
          d: "M126.377 27.3401C127.791 28.7071 129.535 29.7912 131.515 29.7912C132.788 29.7912 134.673 29.367 134.673 28.0471C134.673 27.4343 134.296 27.1987 134.013 27.0101C133.212 26.5859 132.034 26.4916 130.997 26.3502C130.195 26.2088 129.394 26.0673 128.64 25.8316C127.556 25.5488 126.471 25.0774 125.717 24.3232C124.916 23.5219 124.397 22.3434 124.397 21.0236C124.397 18.101 126.896 15.0842 131.845 15.0842C135.993 15.0842 138.492 16.8754 139.717 17.7239L136.512 20.6936C134.862 19.4209 133.306 18.9495 132.081 18.9495C130.054 18.9495 129.582 19.7508 129.582 20.2694C129.582 21.3064 130.714 21.4949 132.411 21.7778C135.946 22.4377 140.094 23.3805 140.094 27.5758C140.094 31.7239 135.899 33.7508 131.751 33.7508C126.848 33.7508 124.303 31.3939 123.172 30.3569L126.377 27.3401Z"                                                                              ,
          fill: "white",}
        )
        , React.createElement('path', { d: "M26.633 23.0976H0V23.899H26.633V23.0976Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 9.38047H0V14.3771H26.633V9.38047Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 1.83839H0V3.11111H26.633V1.83839Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 20.6936H0V21.9663H26.633V20.6936Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 4.66666H0V7.16498H26.633V4.66666Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 16.6397H0V19.138H26.633V16.6397Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 0H0V0.801347H26.633V0Z" , fill: "white",} )
      )
      , React.createElement('defs', null
        , React.createElement('clipPath', { id: "clip0_2_14",}
          , React.createElement('rect', { width: "140", height: "33.9394", fill: "white",} )
        )
      )
    )
  );
};

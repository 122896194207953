import React from 'react';

export const readymindgocloudLogoIcon = () => {
  return (
    React.createElement('svg', {
      width: "140",
      height: "34",
      viewBox: "0 0 140 34"   ,
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      'aria-labelledby': "catTitle",
      role: "img",}

      , React.createElement('title', { id: "catTitle",}, "RM Icon" )
      , React.createElement('g', { clipPath: "url(#clip0_2_14)",}
        , React.createElement('path', { d: "M26.633 23.0976H0V23.899H26.633V23.0976Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 9.38047H0V14.3771H26.633V9.38047Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 1.83839H0V3.11111H26.633V1.83839Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 20.6936H0V21.9663H26.633V20.6936Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 4.66666H0V7.16498H26.633V4.66666Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 16.6397H0V19.138H26.633V16.6397Z" , fill: "white",} )
        , React.createElement('path', { d: "M26.633 0H0V0.801347H26.633V0Z" , fill: "white",} )
      )
      , React.createElement('defs', null
        , React.createElement('clipPath', { id: "clip0_2_14",}
          , React.createElement('rect', { width: "140", height: "33.9394", fill: "white",} )
        )
      )
    )
  );
};

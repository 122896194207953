import React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import {
  ScaffolderFieldExtensions,
  ScaffolderPage,
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,

} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

import { darkTheme, lightTheme } from '@readymindgocloud/theme';
import DarkIcon from '@material-ui/icons/Brightness2';
import LightIcon from '@material-ui/icons/WbSunny';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SamplePluginPage } from '@internal/plugin-sample-plugin';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { HomePage } from './components/home/HomePage';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { BaseCustomInputExtension } from './scaffolder/BaseCustomInputExtension';
import { AnnouncementsPage } from '@k-phoen/backstage-plugin-announcements';

const microsoftAuthProvider = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message:
    'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      React.createElement(SignInPage, { ...props, auto: true, provider: microsoftAuthProvider,} )
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'light',
      title: 'Light',
      variant: 'light',
      icon: React.createElement(LightIcon, null ),
      Provider: ({ children }) => (
        React.createElement(ThemeProvider, { theme: lightTheme,}
          , React.createElement(CssBaseline, null, children)
        )
      ),
    },
    {
      id: 'dark',
      title: 'Dark',
      variant: 'dark',
      icon: React.createElement(DarkIcon, null ),
      Provider: ({ children }) => (
        React.createElement(ThemeProvider, { theme: darkTheme,}
          , React.createElement(CssBaseline, null, children)
        )
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  React.createElement(FlatRoutes, null
    , React.createElement(Route, { path: "/", element: React.createElement(HomepageCompositionRoot, null ),}
      , React.createElement(HomePage, null )
    )
    , React.createElement(Route, { path: "/docs", element: React.createElement(TechDocsIndexPage, null ),} )
    , React.createElement(Route, { path: "/catalog", element: React.createElement(CatalogIndexPage, null ),} )
    , React.createElement(Route, {
      path: "/catalog/:namespace/:kind/:name",
      element: React.createElement(CatalogEntityPage, null ),}

      , entityPage
    )

    , React.createElement(Route, { path: "/announcements", element: React.createElement(AnnouncementsPage, null ),} )
    , React.createElement(Route, {
      path: "/docs/:namespace/:kind/:name/*",
      element: React.createElement(TechDocsReaderPage, null ),}

      , React.createElement(TechDocsAddons, null
        , React.createElement(ReportIssue, null )
      )
    )
    , React.createElement(Route, {
      path: "/create",
      element: 
        React.createElement(ScaffolderPage, {
          groups: [
            {
              title: 'Core',
              filter: entity =>
                entity?.metadata?.tags?.includes('core') ?? false,
            },
            {
              title: 'Infrastructures',
              filter: entity =>
                entity?.metadata?.tags?.includes('architecture') ?? false,
            },
            {
              title: 'Applications',
              filter: entity =>
                entity?.metadata?.tags?.includes('app') ?? false,
            },
          ],}
        )
      ,}

      , React.createElement(ScaffolderFieldExtensions, null
        , React.createElement(BaseCustomInputExtension, null )
      )
    )
    , React.createElement(Route, { path: "/api-docs", element: React.createElement(ApiExplorerPage, null ),} )
    , React.createElement(Route, {
      path: "/tech-radar",
      element: React.createElement(TechRadarPage, { width: 1500, height: 800,} ),}
    )
    , React.createElement(PermissionedRoute, {
      path: "/catalog-import",
      permission: catalogEntityCreatePermission,
      element: React.createElement(CatalogImportPage, null ),}
    )
    , React.createElement(Route, { path: "/search", element: React.createElement(SearchPage, null ),}
      , searchPage
    )
    , React.createElement(Route, { path: "/settings", element: React.createElement(UserSettingsPage, null ),} )
    , React.createElement(Route, { path: "/catalog-graph", element: React.createElement(CatalogGraphPage, null ),} )
    , React.createElement(Route, { path: "/sample-plugin", element: React.createElement(SamplePluginPage, null ),} )
  )
);

const App = () => (
  React.createElement(AppProvider, null
    , React.createElement(AlertDisplay, null )
    , React.createElement(OAuthRequestDialog, null )
    , React.createElement(AppRouter, null
      , React.createElement(Root, null, routes)
    )
  )
);

export default App;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { HomePageStarredEntities } from '@backstage/plugin-home';
import { Content, InfoCard } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { NewAnnouncementBanner } from '@k-phoen/backstage-plugin-announcements';
import news from './news.json';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

export const HomePage = () => {
  const defaultProps = {
    title: news[0].title,
    subheader: news[0].Subtitle,
  };
  const textToShow = React.createElement(Typography, { paragraph: true,}, news[0].text);
  const classes = useStyles();
  return (
    React.createElement(SearchContextProvider, null
      , React.createElement(Content, null
        , React.createElement(Box, { sx: { m: 3 },}
          , React.createElement(Grid, { item: true, md: 12,}
            , React.createElement(NewAnnouncementBanner, null )
          )
          , React.createElement(Grid, { container: true, item: true, xs: 12,}
            , React.createElement(Grid, { item: true, xs: 12, md: 6,}
              , React.createElement(HomePageStarredEntities, null )
            )
            , React.createElement(Grid, { item: true, xs: 12, md: 6,}
              , React.createElement(InfoCard, { ...defaultProps,}, textToShow)
            )
          )
        )
        , React.createElement(Grid, { container: true, item: true, xs: 12, alignItems: "center", direction: "row",}
          , React.createElement(HomePageSearchBar, {
            classes: { root: classes.searchBar },
            placeholder: "Search - Ej: Componentes, Aplicaciones, etc"     ,}
          )
        )
      )
    )
  );
};
